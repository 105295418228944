<template>
  <div class="base-page">
    <router-view/>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss" scoped>

</style>
